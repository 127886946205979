import React from "react";

import { StaticImage } from "gatsby-plugin-image";

/* This example requires Tailwind CSS v2.0+ */
import { ExternalLinkIcon } from "@heroicons/react/solid";

export default function Section1() {
  return (
    <div className="relative bg-slate-800">
      <div className="h-56 bg-emerald-500 sm:h-72 md:absolute md:left-0 md:w-1/2 md:h-full">
        <StaticImage
          className="object-cover w-full h-full"
          src="../../images/geotechnique-maison.jpg"
          alt="Maison fissure"
        />
      </div>
      <div className="relative py-12 px-4 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="md:pl-10 md:ml-auto md:w-1/2">
          <p className="text-base font-semibold tracking-wider text-gray-300 uppercase">
            geotechnique-maison.fr
          </p>
          <h1 className="mt-2 text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
            Votre devis d'étude de sol selon un programme d'essais géotechniques
            personnalisé
          </h1>
          <p className="mt-3 text-lg text-gray-300">
            Simple, économique, pratique
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/etude-sol/devis/"
                className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-gray-900 bg-white rounded-md border border-transparent hover:bg-gray-50"
              >
                Demandez un devis
                <ExternalLinkIcon
                  className="ml-3 -mr-1 w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
